import { Partner } from '@/interfaces/partner'
import axios, { AxiosInstance, AxiosResponse } from 'axios'

// const { VUE_APP_API_DOMAIN } = process.env

export default class PartnerService {
  // private PARTNER_ENDPOINT = VUE_APP_API_DOMAIN
  private PARTNER_ENDPOINT = 'https://parceiro-dot-startmeup-218419.rj.r.appspot.com'

  client: AxiosInstance;

  constructor(client: AxiosInstance = axios) {
    this.client = client
  }

  new(form: Partner) {
    return this.client
      .post(`${this.PARTNER_ENDPOINT}/parceiro/novo`, { ...form })
      .then((response: AxiosResponse) => response.data)
  }

  update(id: string, form: Partial<Partner>) {
    return this.client
      .put(`${this.PARTNER_ENDPOINT}/parceiro/atualiza/${id}`, { ...form })
      .then((response: AxiosResponse) => response.data)
  }

  delete(id) {
    return this.client
      .delete(`${this.PARTNER_ENDPOINT}/parceiro/apaga/${id}`)
      .then((response: AxiosResponse) => response.data)
  }

  all() {
    return this.client
      .get(`${this.PARTNER_ENDPOINT}/parceiro/lista`)
      .then((response: AxiosResponse) => response.data)
  }

  saveDepoimento(partnerId, form) {
    return this.client
      .post(`${this.PARTNER_ENDPOINT}/parceiro/depoimento/novo/${partnerId}`, { ...form })
      .then((response: AxiosResponse) => response.data)
  }

  updateDepoimento(depoimentId, form) {
    return this.client
      .put(`${this.PARTNER_ENDPOINT}/parceiro/depoimento/atualiza/${depoimentId}`, { ...form })
      .then((response: AxiosResponse) => response.data)
  }

  deleteTestimony(partnerId, testimonyId) {
    return this.client
      .delete(`${this.PARTNER_ENDPOINT}/parceiro/depoimento/apaga`, {
        params: {
          idParceiro: partnerId,
          idDepoimento: testimonyId
        }
      })
      .then((response: AxiosResponse) => response.data)
  }

  saveIntegrante(partnerId, form) {
    return this.client
      .post(`${this.PARTNER_ENDPOINT}/parceiro/integrante/novo/${partnerId}`, { ...form })
      .then((response: AxiosResponse) => response.data)
  }

  updateIntegrante(integranteId, form) {
    return this.client
      .put(`${this.PARTNER_ENDPOINT}/parceiro/integrante/atualiza/${integranteId}`, { ...form })
      .then((response: AxiosResponse) => response.data)
  }

  deleteMember(partnerId, memberId) {
    return this.client
      .delete(`${this.PARTNER_ENDPOINT}/parceiro/integrante/apaga`, {
        params: {
          idParceiro: partnerId,
          idIntegrante: memberId
        }
      })
      .then((response: AxiosResponse) => response.data)
  }

  saveHighlight(partnerId, form) {
    return this.client
      .post(`${this.PARTNER_ENDPOINT}/parceiro/destaque/novo/${partnerId}`, { ...form })
      .then((response: AxiosResponse) => response.data)
  }

  updateHighlight(highlightId, form) {
    return this.client
      .put(`${this.PARTNER_ENDPOINT}/parceiro/destaque/atualiza/${highlightId}`, { ...form })
      .then((response: AxiosResponse) => response.data)
  }

  deleteHighlight(partnerId, highlightId) {
    return this.client
      .delete(`${this.PARTNER_ENDPOINT}/parceiro/destaque/apaga`, {
        params: {
          idParceiro: partnerId,
          idDestaque: highlightId
        }
      })
      .then((response: AxiosResponse) => response.data)
  }
}
