
















import Vue from 'vue'
import Component from 'vue-class-component'
import Topbar from '@/components/Topbar.vue'
import Sidebar from '@/components/Sidebar.vue'

import blank from '@/views/layouts/blank.vue'
import dashboard from '@/views/layouts/dashboard.vue'

/** Fix TS errors. This module has no ts support. */
const LayoutBroker = require('vue-layout-broker').default

const layouts = {
  blank,
  dashboard
}

@Component({
  components: {
    LayoutBroker,
    Topbar,
    Sidebar
  }
})
export default class App extends Vue {
  layouts = layouts
}
