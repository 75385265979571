import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueCurrencyFilter from 'vue-currency-filter'
import VueMoment from 'vue-moment'
import Moment from 'moment'
import VModal from 'vue-js-modal'
import 'vue-js-modal/dist/styles.css'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import '@/assets/styles/index.scss'
import 'bootstrap'
import '@/assets/scripts/sidebar'

import Panel from '@/components/shared/Panel.vue'
import InnerContent from '@/components/shared/InnerContent.vue'

import Notifications from 'vue-notification'
import Datepicker from 'vuejs-datepicker'
import JsonExcel from 'vue-json-excel'

import filters from './filters'

require('moment/locale/pt-br')

Vue.config.productionTip = false

/**
 * Declare an http interceptor for 401 status code.
 * Redirect to login page.
 */
axios.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response && error.response.status !== 401) {
      return Promise.reject(error)
    }

    if (error.response && error.response.status === 401) {
      store.dispatch('auth/logout').then(() => {
        router.push('/login')
      })
    }
  }
)

const auth = localStorage.getItem('auth-settings')

if (auth) {
  const parsed = JSON.parse(auth)

  const signedAxios = axios.create({
    headers: {
      authorization: parsed.authorization,
      'public-key-user': parsed['public-key-user'],
      'public-key-entrepreneur': parsed['public-key-entrepreneur'],
      'public-key-investor': parsed['public-key-investor']
    }
  })

  Vue.prototype.$http = signedAxios
}

Vue.use(filters)

Vue.use(VueCurrencyFilter, {
  symbol: 'R$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined
})

Vue.use(VueMoment, { moment: Moment })
Vue.use(Notifications)
Vue.use(VModal)

Vue.component('multiselect', Multiselect)
Vue.component('Panel', Panel)
Vue.component('InnerContent', InnerContent)
Vue.component('Datepicker', Datepicker)
Vue.component('JsonExcel', JsonExcel)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
