import axios, { AxiosInstance, AxiosResponse } from 'axios'

export default class PrestService {
  private ENDPOINT = process.env.VUE_APP_DASHBOARD_API

  client: AxiosInstance;

  constructor(client: AxiosInstance = axios) {
    this.client = client
  }

  getAuthorizationKey() {
    return this.client
      .get(`${this.ENDPOINT}/prest/authorization_token`)
      .then((response: AxiosResponse) => response.data)
  }
}
