import { Investment } from '@/interfaces/pRest/investment'

export interface InvestmentState {
  loading: boolean;
  investments: Investment[];
}

function state(): InvestmentState {
  return {
    loading: false,
    investments: []
  }
}

export default state
