import { RouteConfig } from 'vue-router'
import { isAuthenticated } from '@/middlewares/isAuthenticated'
import { isRoleAllowed } from '@/middlewares/isRoleAllowed'
import { Role } from '@/interfaces/dashboard'

const routes: Array<RouteConfig> = [
  {
    path: '/conversoes',
    name: 'Conversions',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ANALISE, Role.ATENDIMENTO, Role.SUPORTE, Role.MARKETING, Role.COMERCIAL, Role.RI, Role.OFFER_ADMIN, Role.ADMIN],
      layout: 'dashboard',
      title: 'Conversões | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "marketing" */ '../views/pages/conversoes/index.vue')
  },
  {
    path: '/banners',
    name: 'Banners',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.MARKETING, Role.ADMIN],
      layout: 'dashboard',
      title: 'Banners | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "marketing" */ '../views/pages/banners/index.vue')
  },
  {
    path: '/banners/novo',
    name: 'NewBanner',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.MARKETING, Role.ADMIN],
      layout: 'dashboard',
      title: 'Novo Banner | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "marketing" */ '../views/pages/banners/novo.vue')
  },
  {
    path: '/banners/:id/editar',
    name: 'EditBanner',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.MARKETING, Role.ADMIN],
      layout: 'dashboard',
      title: 'Editar Banner | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "marketing" */ '../views/pages/banners/editar.vue')
  }
]

export default routes
