import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'blank',
      authenticated: false,
      title: 'Login | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/pages/login.vue')
  },

  {
    path: '/logout',
    name: 'Logout',
    meta: {
      layout: 'blank',
      authenticated: false,
      title: 'Logout...'
    },
    component: () => import(/* webpackChunkName: "logout" */ '../views/pages/logout.vue')
  }
]

export default routes
