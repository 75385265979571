import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import configRoutes from './config-routes'
import userRoutes from './user-routes'
import authRoutes from './auth-routes'
import dashboardRoutes from './dashboard-routes'
import feedRoutes from './feed-routes'
import investmentRoutes from './investment-routes'
import offerRoutes from './offer-routes'
import marketingRoutes from './marketing-routes'
import suporteRoutes from './support-routes'
import analiseRoutes from './analise-routes'
import paymentLinkRoutes from './payment-link.routes'
import documentRoutes from './document-routes'
import imageRoutes from './image-routes'
import colideresRoutes from './colideres-routes'
import store from '../store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  ...dashboardRoutes,
  ...configRoutes,
  ...userRoutes,
  ...authRoutes,
  ...feedRoutes,
  ...investmentRoutes,
  ...offerRoutes,
  ...marketingRoutes,
  ...suporteRoutes,
  ...analiseRoutes,
  ...paymentLinkRoutes,
  ...imageRoutes,
  ...documentRoutes,
  ...colideresRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

store.commit('auth/initializeStore')
store.dispatch('prest/requestAuthorizationKey')

router.beforeEach((to: Route, from: Route, next) => {
  Vue.nextTick(() => {
    document.title = to.meta?.title || 'Dashboard | SMU Investimentos'
  })

  const middlewares = to.meta?.middlewares || []

  middlewares.forEach((middleware: Function) => {
    middleware(store.state, to, next)
  })

  next()
})

export default router
