import { RouteConfig } from 'vue-router'
import { isAuthenticated } from '@/middlewares/isAuthenticated'
import { isRoleAllowed } from '@/middlewares/isRoleAllowed'
import { Role } from '@/interfaces/dashboard'

const routes: Array<RouteConfig> = [
  {
    path: '/link-de-pagamento',
    name: 'ListPaymentLinks',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ANALISE, Role.ATENDIMENTO, Role.SUPORTE, Role.MARKETING, Role.COMERCIAL, Role.RI, Role.OFFER_ADMIN, Role.ADMIN],
      layout: 'dashboard',
      title: 'Link de Pagamento | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "payment-links" */ '../views/pages/link-de-pagamento/index.vue')
  },
  {
    path: '/link-de-pagamento/novo',
    name: 'NewPaymentLink',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ANALISE, Role.ATENDIMENTO, Role.SUPORTE, Role.MARKETING, Role.COMERCIAL, Role.RI, Role.OFFER_ADMIN, Role.ADMIN],
      layout: 'dashboard',
      title: 'Novo | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "payment-links" */ '../views/pages/link-de-pagamento/novo.vue')
  }
]

export default routes
