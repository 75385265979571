
























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class Panel extends Vue {
  @Prop({ required: false }) title!: string
}
