import { RouteConfig } from 'vue-router'
import { isAuthenticated } from '@/middlewares/isAuthenticated'
import { isRoleAllowed } from '@/middlewares/isRoleAllowed'
import { Role } from '@/interfaces/dashboard'

const routes: Array<RouteConfig> = [
  {
    path: '/imagens/upload',
    name: 'ImagesUpload',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ADMIN, Role.OFFER_ADMIN],
      layout: 'dashboard',
      title: 'Upload | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "imagens" */ '../views/pages/imagens/upload.vue')
  }
]

export default routes
