


















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import InnerLoading from '@/components/shared/InnerLoading.vue'

/**
 * Você também consegue escutar eventos do componente InnerLoading. Caso seja necessário:
 *
 * <inner-loading
 *   :showing="loading"
 *   @onLoadingStart="onLoadingStart"
 *   @onLoadingFinish="onLoadingFinish"
 * ></inner-loading>
 *
 *   ..
 *
 * onLoadingStart(el) {
 * }
 *
 * onLoadingFinish(el) {
 * }
 */
@Component({
  components: {
    InnerLoading
  }
})
export default class InnerContent extends Vue {
  @Prop({ default: '10vh' }) minHeight!: string
  @Prop({ required: true, default: false }) loading!: boolean
}
