import { GetterTree } from 'vuex'
import { StateInterface } from '../index'
import { PartnerState } from './state'

const getters: GetterTree<PartnerState, StateInterface> = {
  partners({ partners }) {
    return partners
  }
}

export default getters
