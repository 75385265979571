import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import investment from './investment'
import partners from './partners'
import prest from './prest'
import { InvestmentState } from './investment/state'
import { PartnerState } from './partners/state'

export interface StateInterface {
  auth: unknown;
  investments: InvestmentState;
  partners: PartnerState;
}

Vue.use(Vuex)

export default new Vuex.Store<StateInterface>({
  state: undefined,
  mutations: {},
  actions: {},
  modules: {
    auth,
    investment,
    partners,
    prest
  }
})
