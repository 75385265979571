import { ActionTree } from 'vuex'
import { StateInterface } from '../index'
import { PartnerState } from './state'

import PartnerService from '@/services/Partner.service'

const actions: ActionTree<PartnerState, StateInterface> = {
  load({ commit }) {
    const partnerService = new PartnerService()

    return partnerService.all()
      .then((partners) => {
        commit('setPartners', partners)
      })
  },

  async find({ getters }, id) {
    await this.dispatch('partners/load')
    return getters.partners.find(p => p.id === id)
  },

  save(ctx, { form }) {
    const partnerService = new PartnerService()
    return partnerService.new(form)
  },

  update(ctx, { id, form }) {
    const partnerService = new PartnerService()
    return partnerService.update(id, form)
  },

  delete(ctx, id) {
    const partnerService = new PartnerService()
    return partnerService.delete(id)
  },

  saveDepoiment(ctx, { partnerId, form }) {
    const partnerService = new PartnerService()
    return partnerService.saveDepoimento(partnerId, form)
  },

  updateDepoiment(ctx, { depoimentId, form }) {
    const partnerService = new PartnerService()
    return partnerService.updateDepoimento(depoimentId, form)
  },

  deleteTestimony(ctx, { partnerId, testimonyId }) {
    const partnerService = new PartnerService()
    return partnerService.deleteTestimony(partnerId, testimonyId)
  },

  saveIntegrante(ctx, { partnerId, form }) {
    const partnerService = new PartnerService()
    return partnerService.saveIntegrante(partnerId, form)
  },

  updateIntegrante(ctx, { integranteId, form }) {
    const partnerService = new PartnerService()
    return partnerService.updateIntegrante(integranteId, form)
  },

  deleteMember(ctx, { partnerId, memberId }) {
    const partnerService = new PartnerService()
    return partnerService.deleteMember(partnerId, memberId)
  },

  saveHighlight(ctx, { partnerId, form }) {
    const partnerService = new PartnerService()
    return partnerService.saveHighlight(partnerId, form)
  },

  updateHighlight(ctx, { highlightId, form }) {
    const partnerService = new PartnerService()
    return partnerService.updateHighlight(highlightId, form)
  },

  deleteHighlight(ctx, { partnerId, highlightId }) {
    const partnerService = new PartnerService()
    return partnerService.deleteHighlight(partnerId, highlightId)
  }
}

export default actions
