import PrestService from '@/services/Prest.service'
import { Module } from 'vuex'
import { PrestState } from '@/interfaces/store'
import Model from '@/lib/prest-query-builder'
import { Investment } from '@/interfaces/pRest/investment'
import { Investor } from '@/interfaces/pRest/investor'
import { Invoice, Order } from '@/interfaces/investment'
import { Address } from '@/interfaces/investor'

const { VUE_APP_API_PREST_NEW, VUE_APP_DASHBOARD_API } = process.env

const PREST_CONFIG = {
  investment: {
    url: `${VUE_APP_API_PREST_NEW}/Investimento/public/investimento`
  },
  investor: {
    url: `${VUE_APP_API_PREST_NEW}/Investimento/public/investidor`
  },
  address: {
    url: `${VUE_APP_API_PREST_NEW}/Investimento/public/endereco`
  },
  order: {
    url: `${VUE_APP_API_PREST_NEW}/Ordem/public/investment_order`
  },
  invoice: {
    url: `${VUE_APP_API_PREST_NEW}/Ordem/public/invoice`
  }
}

// eslint-disable-next-line
const prestModule: Module<any, any> = {
  namespaced: true,

  state: {
    authorizationKey: null,
    endpoint: VUE_APP_API_PREST_NEW,
    models: {
    },
    loading: false
  } as PrestState,

  getters: {
    endpoint(state) {
      return state.endpoint
    },
    authorizationKey(state) {
      return state.authorizationKey
    },
    loading(state) {
      return state.loading
    },
    model(getters) {
      return (id) => new Model({
        url: PREST_CONFIG[id].url,
        authorization_token: getters.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })
    }
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.authorizationKey = token
    },

    SET_LOADING(state, value) {
      state.loading = value
    },

    SET_MODEL(state, payload) {
      state.models[payload.id] = payload.model
    }
  },

  actions: {
    async requestAuthorizationKey({ commit, dispatch }) {
      commit('SET_LOADING', true)
      const prestService = new PrestService()
      const token = await prestService.getAuthorizationKey().then((auth) => auth.token)
      commit('SET_TOKEN', token)
      dispatch('initializeModels')
      commit('SET_LOADING', false)
    },

    initializeModels({ commit, getters }) {
      const investmentModel = new Model<Investment>({
        url: `${VUE_APP_API_PREST_NEW}/Investimento/public/investimento`,
        authorization_token: getters.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      const investorModel = new Model<Investor>({
        url: `${VUE_APP_API_PREST_NEW}/Investimento/public/investidor`,
        authorization_token: getters.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      const orderModel = new Model<Order>({
        url: `${VUE_APP_API_PREST_NEW}/Ordem/public/investment_order`,
        authorization_token: getters.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      const invoiceModel = new Model<Invoice>({
        url: `${VUE_APP_API_PREST_NEW}/Ordem/public/invoice`,
        authorization_token: getters.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      const addressModel = new Model<Address>({
        url: `${VUE_APP_API_PREST_NEW}/Investimento/public/endereco`,
        authorization_token: getters.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      commit('SET_MODEL', { id: 'investment', model: investmentModel })
      commit('SET_MODEL', { id: 'investor', model: investorModel })
      commit('SET_MODEL', { id: 'order', model: orderModel })
      commit('SET_MODEL', { id: 'invoice', model: invoiceModel })
      commit('SET_MODEL', { id: 'address', model: addressModel })
    }
  }
}

export default prestModule
