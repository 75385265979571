import { BaseModel, JoinQuery, ModelConfig, PageOptions, Pagination, WhereQuery } from './interfaces'
import { _pageOptions, _pagination } from './defaults'

export default class Model<T> extends BaseModel {
  private config: ModelConfig;
  private pipeline: string[] = [];

  private paginate = false;
  private _pagination: Pagination = {} as Pagination
  private _order = '';

  constructor(config: ModelConfig) {
    super(config)
    this.config = config
  }

  baseUrl(): string {
    return this.config.url || `${this.config.https ? 'https://' : 'http://'}${this.config.domain}/${this.config.db}/public/${this.config.table}`
  }

  where(wQuery: WhereQuery): this {
    const q = `${wQuery.field}=${wQuery.operator}.${wQuery.value}`
    this.pipeline.push(q)
    return this
  }

  select(fields: string[]): this {
    const q = `_select=${fields.join(',')}`
    this.pipeline.push(q)
    return this
  }

  join(jQuery: JoinQuery): this {
    const q = `_join=${jQuery.type}:${jQuery.tableJoin}:${jQuery.tableJoin}.${jQuery.tableJoinKey}:${jQuery.operator}:${jQuery.table}.${jQuery.tableKey}`
    this.pipeline.push(q)
    return this
  }

  order(field: string): this {
    const q = `_order=${field}`
    this._order = q
    this.pipeline.push(q)
    return this
  }

  count(field = '*'): Promise<number> {
    this.pipeline.push(`_count=${field}`)

    const queryString = '?' + this.pipeline.join('&')

    const endpoint = `${this.baseUrl()}${queryString}`

    return this.client
      .get<{ count: number }>(endpoint)
      .then(response => response.data.count)
      .then((count: number) => {
        this.paginate = false
        this.pipeline = []

        return count
      })
  }

  pagination(paginationOptions: PageOptions = _pageOptions): this {
    const q = `_page=${paginationOptions.page}&_page_size=${paginationOptions.pageSize}`

    this._pagination = { ..._pagination, ...paginationOptions, _q: q }
    this.paginate = true
    this.pipeline.push(q)

    return this
  }

  private buildPagination({ total, pageSize, page }: { total: number; pageSize: number; page: number }): Pagination {
    let totalPages = 0
    let nextPage = 0
    let prevPage = 0
    let isLast = false
    let isFirst = false

    if (total === 0) totalPages = 0
    if (total > 0 && total <= pageSize) totalPages = 1
    if (total > 0) totalPages = Math.ceil(total / this._pagination.pageSize)

    nextPage = page + 1
    prevPage = page > 1 ? page - 1 : 1
    isLast = page === totalPages
    isFirst = page === 1

    return {
      page,
      pageSize,
      total,
      totalPages,
      nextPage,
      prevPage,
      isLast,
      isFirst
    }
  }

  buildTotalEndpoint(endpoint) {
    const cleaned = endpoint
      .replace(this._pagination._q as string, '')
      .replace(this._order, '')

    return `${cleaned}${cleaned.match(/\?/) ? '&' : '?'}_count=*`
  }

  clear() {
    this.pipeline = []

    return this
  }

  async run(): Promise< T[] | { pagination: PageOptions; items: T[]} > {
    const queryString = this.pipeline.length ? `?${this.pipeline.join('&')}` : ''

    const endpoint = `${this.baseUrl()}${queryString}`
    const totalEndpoint = this.buildTotalEndpoint(endpoint)

    let dataPromise
    let countPromise

    if (this.config.proxy) {
      dataPromise = this.client
        .post(this.config.proxy, {
          endpoint,
          authorization_token: this.config.authorization_token
        })

      countPromise = this.client
        .post(this.config.proxy, {
          endpoint: totalEndpoint,
          authorization_token: this.config.authorization_token
        })
    } else {
      dataPromise = this.client
        .get<T[]>(endpoint)

      countPromise = this.client.get<{ count: number}>(totalEndpoint).then(r => r.data.count)
    }

    const dataResponse = await dataPromise.then((r) => r.data)
    const countResponse = await countPromise
      .then((r) => {
        return r.data && r.data[0] && r.data[0].count
      })
      .catch(() => 0)

    if (!this.paginate) return dataResponse

    const pagination = this.buildPagination({
      total: countResponse,
      pageSize: this._pagination.pageSize,
      page: this._pagination.page
    })

    this.paginate = false
    this.pipeline = []

    return Promise.resolve({
      pagination,
      items: dataResponse
    })
  }
}
