import { MutationTree } from 'vuex'
import { PartnerState } from './state'

const mutation: MutationTree<PartnerState> = {
  setPartners(state, payload) {
    state.partners = payload
  }
}

export default mutation
