import { User } from '@/interfaces/user'
import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { Pagination } from '@/interfaces/pagination'

export default class UserService {
  private ENDPOINT = 'https://api.startmeup.com.br/Gateway/v1'
  private CADASTRO_ENDPOINT = 'https://cadastro-dot-startmeup-218419.rj.r.appspot.com/Cadastro/v1'

  client: AxiosInstance;

  constructor(client: AxiosInstance = axios) {
    this.client = client
  }

  all(options: Record<string, any> = {}): Promise<{ items: User[]; pagination: Pagination }> {
    if (options.raw) {
      return this.client
        .get(`${this.CADASTRO_ENDPOINT}/basico/lista`)
        .then((response: AxiosResponse) => response.data)
    } else {
      return this.client
        .get(`${this.ENDPOINT}/cadastro/lista/paginas`, { params: options })
        .then((response: AxiosResponse) => response.data)
        .then((data) => {
          return {
            items: data.items,
            pagination: {
              itemsPorPagina: data.itemsPorPagina,
              numeroDePaginas: data.numeroDePaginas,
              pagina: data.pagina,
              proximaPagina: data.proximaPagina,
              temPaginaAnterior: data.temPaginaAnterior,
              temProximaPagina: data.temProximaPagina
            }
          }
        })
    }
  }

  profile(key) {
    return this.client
      .get(`${this.ENDPOINT}/perfil-basico/${key}`)
      .then((response) => response.data)
  }

  resume() {
    return this.client
      .get(`${this.CADASTRO_ENDPOINT}/consolidado`)
      .then(response => response.data)
      .catch(() => {
        return { total_cadastrado: 0 }
      })
  }

  updateUserProfile({ cpf, profile }): Promise<any> {
    return this.client
      .put(`${this.ENDPOINT}/cadastro/perfil-investidor/${cpf}`, { ...profile })
      .then((response) => response.data)
  }

  complementaryData(email) {
    return this.client
      .get(`${this.ENDPOINT}/dados-complementares/${email}`)
      .then((r) => r.data)
  }

  fetchComplementaryData(cpf) {
    return this.client
      .get(`${this.ENDPOINT}/dados-complementares/atualiza?cpf=${cpf}`)
      .then((r) => r.data)
  }
}
