import { GetterTree } from 'vuex'
import { StateInterface } from '../index'
import { InvestmentState } from './state'

const getters: GetterTree<InvestmentState, StateInterface> = {
  investments({ investments }) {
    return investments
  },

  loading({ loading }) {
    return loading
  }
}

export default getters
