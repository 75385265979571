import axios, { AxiosInstance, AxiosResponse } from 'axios'

interface LoginCredentials {
  senha: string;
  email: string;
}

export default class AuthService {
  private GATEWAY_ENDPOINT = 'https://api.startmeup.com.br/Gateway/v1';

  private DASHBOARD_ENDPOINT = 'http://prest.users.scale.virtualcloud.com.br/dashboard/public';

  client: AxiosInstance;

  constructor(client: AxiosInstance = axios) {
    this.client = client
  }

  login(credentials: LoginCredentials) {
    return this.client
      .post(
        `${this.GATEWAY_ENDPOINT}/auth`,
        { ...credentials }
      )
      .then((response: AxiosResponse) => response)
  }

  dashboardUser(email: string) {
    return this.client
      .get(`${this.DASHBOARD_ENDPOINT}/users`, { params: { email } })
      .then((response) => {
        if (response.data.length) {
          return response.data[0]
        }
        throw new Error('Usuário não encontrado.')
      })
  }

  profile(publicKey: string) {
    return this.client
      .get(`${this.GATEWAY_ENDPOINT}/perfil-basico/${publicKey}`)
      .then((response) => response.data)
  }
}
