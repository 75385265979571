












import Vue from 'vue'
import Component from 'vue-class-component'
import Topbar from '@/components/Topbar.vue'
import Sidebar from '@/components/Sidebar.vue'

@Component({
  components: {
    Topbar,
    Sidebar
  }
})
export default class DashboardLayout extends Vue {}
