import { RouteConfig } from 'vue-router'
import { isAuthenticated } from '@/middlewares/isAuthenticated'
import { isRoleAllowed } from '@/middlewares/isRoleAllowed'
import { Role } from '@/interfaces/dashboard'

const routes: Array<RouteConfig> = [
  {
    path: '/ofertas-colider',
    name: 'colideres-lista',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ANALISE, Role.ATENDIMENTO, Role.SUPORTE, Role.MARKETING, Role.COMERCIAL, Role.RI, Role.OFFER_ADMIN, Role.ADMIN],
      layout: 'dashboard',
      title: 'Oferta Colíder | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "colideres" */ '../views/pages/colideres/index.vue')
  },

  {
    path: '/ofertas-colider/nova',
    name: 'colideres-new',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ADMIN, Role.OFFER_ADMIN, Role.COMERCIAL],
      layout: 'dashboard',
      title: 'Nova Oferta Colíder | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "colideres" */ '../views/pages/colideres/new.vue')
  },

  {
    path: '/ofertas-colider/:url/subconta',
    name: 'colider-subconta',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ADMIN, Role.OFFER_ADMIN, Role.COMERCIAL],
      layout: 'dashboard',
      title: 'Colíder | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "colideres" */ '../views/pages/colideres/_url/subconta.vue')
  },

  {
    path: '/ofertas-colider/:url',
    name: 'colider-index',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ADMIN, Role.OFFER_ADMIN, Role.COMERCIAL],
      layout: 'dashboard',
      title: 'Colíder | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "colideres" */ '../views/pages/colideres/_url/index.vue')
  }
]

export default routes
