



































































































import Vue from 'vue'
import Component from 'vue-class-component'
import SidebarEffects from '@/assets/scripts/sidebar'
import { DashboardUser, Role } from '@/interfaces/dashboard'

interface MenuItem {
  label: string;
  iconClass?: string;
  path?: string;
  children?: MenuItem[];
  allowedRoles?: Role[];
}

@Component({})
export default class Sidebar extends Vue {
  dashboardUser: DashboardUser = this.$store.state.auth.dashboardUser

  menuItems: MenuItem[] = [
    {
      label: 'Dashboard',
      iconClass: 'ti-home c-blue-500',
      path: '/'
    },
    {
      label: 'Templates',
      iconClass: 'ti-receipt c-orange-900',
      allowedRoles: [Role.ADMIN, Role.OFFER_ADMIN, Role.ANALISE],
      path: '/templates'
    },
    {
      label: 'Ofertas',
      iconClass: 'ti-view-grid c-purple-500',
      allowedRoles: [Role.ADMIN, Role.OFFER_ADMIN, Role.RI],
      children: [
        { label: 'Lista', path: '/ofertas', allowedRoles: [Role.ADMIN, Role.OFFER_ADMIN] },
        { label: 'Nova Oferta', path: '/ofertas/setup', allowedRoles: [Role.ADMIN, Role.OFFER_ADMIN] },
        { label: 'RI', path: '/ofertas/ri', allowedRoles: [Role.ADMIN, Role.RI] },
        { label: 'Setores de Atuação', path: '/ofertas/setores-de-atuacao', allowedRoles: [Role.ADMIN, Role.OFFER_ADMIN] },
        { label: 'Tags', path: '/ofertas/tags', allowedRoles: [Role.ADMIN, Role.OFFER_ADMIN] }
      ]
    },
    {
      label: 'Oferta Colíder',
      iconClass: 'ti-cup c-purple-500',
      allowedRoles: [Role.ADMIN, Role.OFFER_ADMIN, Role.COMERCIAL],
      children: [
        { label: 'Lista', path: '/ofertas-colider', allowedRoles: [Role.ADMIN, Role.OFFER_ADMIN, Role.COMERCIAL] },
        { label: 'Nova Oferta Colíder', path: '/ofertas-colider/nova', allowedRoles: [Role.ADMIN, Role.OFFER_ADMIN, Role.COMERCIAL] }
      ]
    },
    {
      label: 'Imagens',
      iconClass: 'ti-image c-gray-500',
      allowedRoles: [Role.ADMIN, Role.OFFER_ADMIN],
      children: [
        { label: 'Upload', path: '/imagens/upload', allowedRoles: [Role.ADMIN, Role.OFFER_ADMIN] }
      ]
    },
    {
      label: 'Usuários',
      iconClass: 'ti-user c-brown-500',
      children: [
        { label: 'Lista', path: '/usuarios' }
      ]
    },
    {
      label: 'Análise',
      iconClass: 'ti-pulse c-brown-500',
      path: '/analise'
      /* children: [
        { label: 'Lista', path: '/usuarios' },
      ], */
    },
    {
      label: 'Investimentos',
      iconClass: 'ti-money c-green-500',
      children: [
        { label: 'Todos', path: '/investimentos' },
        { label: 'Lista por Oferta', path: '/investimentos/oferta' },
        {
          label: 'Lista de Espera',
          path: '/investimentos/lista-de-espera',
          allowedRoles: [Role.ADMIN, Role.ATENDIMENTO]
        }
      ]
    },
    {
      label: 'Link de Pagamento',
      iconClass: 'ti-link c-blue-500',
      allowedRoles: [Role.ATENDIMENTO, Role.MARKETING, Role.RI, Role.OFFER_ADMIN, Role.SUPORTE, Role.ADMIN, Role.COMERCIAL, Role.ANALISE],
      children: [
        { label: 'Novo', path: '/link-de-pagamento/novo' },
        { label: 'Lista', path: '/link-de-pagamento' }
      ]
    },
    {
      label: 'Marketing',
      iconClass: 'ti-stats-up c-orange-800',
      allowedRoles: [Role.MARKETING, Role.ADMIN],
      children: [
        { label: 'Banners', path: '/banners' },
        { label: 'Conversões', path: '/conversoes' }
      ]
    },
    {
      label: 'Eventos',
      iconClass: 'ti-list c-orange-500',
      children: [
        { label: 'Lista', path: '/feed' }
      ]
    },
    {
      label: 'Suporte',
      iconClass: 'ti-hummer c-pink-500',
      allowedRoles: [Role.ATENDIMENTO, Role.MARKETING, Role.RI, Role.OFFER_ADMIN, Role.SUPORTE, Role.ADMIN, Role.COMERCIAL, Role.ANALISE],
      children: [
        {
          label: 'Consultar Extrato IUGU',
          path: '/suporte/consultar-extrato',
          allowedRoles: [Role.SUPORTE, Role.ADMIN, Role.COMERCIAL]
        },
        {
          label: 'Consultar Faturas',
          path: '/suporte/consultar-faturas',
          allowedRoles: [Role.SUPORTE, Role.ADMIN, Role.ATENDIMENTO, Role.COMERCIAL]
        },
        {
          label: 'Cancelar Faturas',
          path: '/suporte/cancelar-faturas',
          allowedRoles: [Role.SUPORTE, Role.ADMIN]
        },
        {
          label: 'Perfil do Investidor',
          path: '/suporte/perfil-do-investidor',
          allowedRoles: [Role.SUPORTE, Role.ADMIN]
        },
        {
          label: 'Autenticação',
          path: '/suporte/autenticar-usuarios',
          allowedRoles: [Role.SUPORTE, Role.ADMIN]
        },
        {
          label: 'Tickets',
          path: '/suporte/bug-reporting',
          allowedRoles: [Role.ATENDIMENTO, Role.MARKETING, Role.RI, Role.OFFER_ADMIN, Role.SUPORTE, Role.ADMIN, Role.COMERCIAL, Role.ANALISE]
        }
      ]
    },
    {
      label: 'Configurações',
      iconClass: 'ti-settings c-gray-500',
      allowedRoles: [Role.ADMIN],
      children: [
        { label: 'Acessos', path: '/configuracoes/acessos' },
        { label: 'Novo Acesso', path: '/configuracoes/novo-acesso' }
      ]
    }
  ]

  mounted() {
    SidebarEffects.init()
  }

  isUserAllowed(allowedRoles: Role[], userRoles: Role[]): boolean {
    if (!allowedRoles) return true

    return userRoles.some((userRole) => allowedRoles.indexOf(Role[userRole]) > -1)
  }
}
