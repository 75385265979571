export interface PartnerState {
  partners: [];
}

function state(): PartnerState {
  return {
    partners: []
  }
}

export default state
