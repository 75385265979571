import { RouteConfig } from 'vue-router'
import { isAuthenticated } from '@/middlewares/isAuthenticated'
import { isRoleAllowed } from '@/middlewares/isRoleAllowed'
import { Role } from '@/interfaces/dashboard'

const routes: Array<RouteConfig> = [
  {
    path: '/suporte/perfil-do-investidor',
    name: 'PerfilDoInvestidor',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.SUPORTE, Role.ADMIN],
      layout: 'dashboard',
      title: 'Perfil do Investidor | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "suporte" */ '../views/pages/suporte/perfil-do-investidor.vue')
  },
  {
    path: '/suporte/consultar-extrato',
    name: 'ConsultarExtrato',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.SUPORTE, Role.ADMIN, Role.COMERCIAL],
      layout: 'dashboard',
      title: 'Consultrar Extrato | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "suporte" */ '../views/pages/suporte/consultar-extrato.vue')
  },
  {
    path: '/suporte/cancelar-faturas',
    name: 'CancelarFaturas',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.SUPORTE, Role.ADMIN],
      layout: 'dashboard',
      title: 'Cancelar Faturas | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "suporte" */ '../views/pages/suporte/cancelar-faturas.vue')
  },
  {
    path: '/suporte/consultar-faturas',
    name: 'ConsultarFaturas',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ATENDIMENTO, Role.SUPORTE, Role.COMERCIAL, Role.ADMIN],
      layout: 'dashboard',
      title: 'Consultar Faturas | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "suporte" */ '../views/pages/suporte/consultar-faturas.vue')
  },
  {
    path: '/suporte/autenticar-usuarios',
    name: 'UserAuthentication',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.SUPORTE, Role.ADMIN],
      layout: 'dashboard',
      title: 'Autenticar Usuários | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "suporte" */ '../views/pages/suporte/autenticacao.vue')
  },
  {
    path: '/suporte/bug-reporting',
    name: 'BugReporting',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ANALISE, Role.ATENDIMENTO, Role.SUPORTE, Role.MARKETING, Role.COMERCIAL, Role.RI, Role.OFFER_ADMIN, Role.ADMIN],
      layout: 'dashboard',
      title: 'Bug Reporting | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "suporte" */ '../views/pages/suporte/bug-reporting/index.vue')
  }
]

export default routes
