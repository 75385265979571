import { MutationTree } from 'vuex'
import { InvestmentState } from './state'

const mutation: MutationTree<InvestmentState> = {
  setInvestments(state, payload) {
    state.investments = payload
  },

  setLoading(state, payload) {
    state.loading = payload
  }
}

export default mutation
