import AuthService from '@/services/Auth.service'
import DashboardService from '@/services/Dashboard.service'
import { Auth, Credentials } from '@/interfaces/auth'
import { Module } from 'vuex'
import { DashboardUser } from '@/interfaces/dashboard'
import { User } from '@/interfaces/user'
import { AuthState } from '@/interfaces/store'
import UserService from '@/services/User.service'

// eslint-disable-next-line
const authModule: Module<any, any> = {
  namespaced: true,

  state: {
    auth: null,
    user: null,
    dashboardUser: null
  } as AuthState,

  getters: {
    authenticated(state) {
      return state.user && state.auth && state.dashboardUser
    },
    dashboardUser(state) {
      return state.dashboardUser
    },
    user(state) {
      return state.user
    },
    publicKey(state) {
      return state.auth['public-key-user']
    }
  },

  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('dashboard-user')) {
        try {
          const dashboardUser = JSON.parse(localStorage.getItem('dashboard-user') || '')

          if (dashboardUser) state.dashboardUser = dashboardUser
        } catch (e) {
          console.log(e)
        }
      }

      if (localStorage.getItem('smu-user')) {
        try {
          const user = JSON.parse(localStorage.getItem('smu-user') || '')

          if (user) state.user = user
        } catch (e) {
          console.log(e)
        }
      }

      if (localStorage.getItem('auth-settings')) {
        try {
          const auth = JSON.parse(localStorage.getItem('auth-settings') as string)
          state.auth = auth
        } catch (e) {
          console.log(e)
        }
      }
    },

    LOGOUT(state) {
      state.auth = null
      state.user = null
      state.dashboardUser = null
    },

    SET_AUTH(state: AuthState, auth: Auth) {
      localStorage.setItem('auth-settings', JSON.stringify(auth))
      state.auth = auth
    },

    SET_USER(state: AuthState, user: User) {
      localStorage.setItem('smu-user', JSON.stringify(user))
      state.user = user
    },

    SET_DASHBOARD_USER(state: AuthState, dashboardUser: DashboardUser) {
      localStorage.setItem('dashboard-user', JSON.stringify(dashboardUser))
      state.dashboardUser = dashboardUser
    }
  },

  actions: {
    async login({ dispatch, commit }, credentials: Credentials) {
      const authService = new AuthService()
      const dashService = new DashboardService()

      const loginResponse = await authService.login(credentials)
      const dashboardUser = await dashService.findUserByEmail(credentials.email)

      const auth: Auth = {
        authorization: loginResponse.headers.authorization,
        'public-key-entrepreneur': loginResponse.headers['public-key-entrepreneur'],
        'public-key-investor': loginResponse.headers['public-key-investor'],
        'public-key-user': loginResponse.headers['public-key-user']
      }

      commit('SET_AUTH', auth)
      commit('SET_DASHBOARD_USER', dashboardUser)

      return dispatch('getProfile', credentials.email)
    },

    async getProfile({ commit }: { commit: Function }, email: string) {
      try {
        const userService = new UserService()
        const user = await userService.profile(email)

        commit('SET_USER', user)
      } catch (e) {
        commit('SET_USER', null)
        commit('SET_AUTH', null)
      }
    },

    logout({ commit }: { commit: Function }) {
      return new Promise((resolve) => {
        commit('LOGOUT')
        localStorage.removeItem('auth-settings')
        localStorage.removeItem('dashboard-user')
        localStorage.removeItem('smu-user')
        resolve()
      })
    }
  }
}

export default authModule
