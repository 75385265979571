import { RouteConfig } from 'vue-router'
import { isAuthenticated } from '@/middlewares/isAuthenticated'

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    meta: {
      middlewares: [isAuthenticated],
      layout: 'dashboard',
      title: 'Dashboard | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/pages/dashboard.vue')
  }
]

export default routes
