import { DashboardUser, Role } from '@/interfaces/dashboard'
import { AuthState } from '@/interfaces/store'
import { Route } from 'vue-router'

export const isRoleAllowed = (state, route: Route, next: Function) => {
  const dashboardUser: DashboardUser = (state.auth as AuthState).dashboardUser as DashboardUser
  const allowedRoles = route.meta?.allowedRoles || []

  try {
    const roles = dashboardUser.roles as unknown as string[]
    const isAllowed = roles.some(role => allowedRoles.indexOf(Role[role]) > -1)

    if (isAllowed) {
      next()
      return
    }

    next('/')
  } catch (e) {
    next('/logout')
  }
}
