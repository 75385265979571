























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class InnerLoading extends Vue {
  @Prop({ required: true, default: false }) showing!: boolean
}
