const pow = Math.pow
const floor = Math.floor
const abs = Math.abs
const log = Math.log
const abbrev = 'KMB' // could be an array of strings: [' m', ' Mo', ' Md']

function round(n, precision) {
  const prec = Math.pow(10, precision)
  return Math.round(n * prec) / prec
}

export default (n) => {
  let base = floor(log(abs(n)) / log(1000))
  const suffix = abbrev[Math.min(2, base - 1)]
  base = abbrev.indexOf(suffix) + 1
  return suffix ? round(n / pow(1000, base), 2) + suffix : '' + n
}
