export interface DashboardUser {
  id?: string;
  email: string;
  roles: string;
  _roles?: Role[];
}

export enum Role {
  ANALISE='Análise',
  ATENDIMENTO='Operações',
  SUPORTE='Suporte',
  MARKETING='Marketing',
  COMERCIAL='Comercial',
  RI='RI',
  OFFER_ADMIN='Administrador de Ofertas',
  ADMIN='Administrador'
}

export interface DashboardResume {
  quantidade: number;
  total: number;
  total_cancelado: number;
  total_pago: number;
  total_pendente: number;
  total_reembolsado: number;
  valor_total_taxas: number;
}
