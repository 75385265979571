import { isAuthenticated } from '@/middlewares/isAuthenticated'
import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: '/usuarios',
    name: 'Users',
    meta: {
      middlewares: [isAuthenticated],
      layout: 'dashboard',
      authenticated: true,
      title: 'Usuários | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "users" */ '../views/pages/usuarios/index.vue')
  },

  {
    path: '/usuarios/:email',
    name: 'ShowUser',
    meta: {
      middlewares: [isAuthenticated],
      layout: 'dashboard',
      authenticated: true,
      title: 'Visualizar | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "users" */ '../views/pages/usuarios/_email.vue')
  }
]

export default routes
