import { RouteConfig } from 'vue-router'
import { isAuthenticated } from '@/middlewares/isAuthenticated'
import { isRoleAllowed } from '@/middlewares/isRoleAllowed'
import { Role } from '@/interfaces/dashboard'

const routes: Array<RouteConfig> = [
  {
    path: '/analise',
    name: 'Analise',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [
        Role.ANALISE,
        Role.ATENDIMENTO,
        Role.SUPORTE,
        Role.MARKETING,
        Role.COMERCIAL,
        Role.RI,
        Role.OFFER_ADMIN,
        Role.ADMIN
      ],
      layout: 'dashboard',
      title: 'Analise | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "analise" */ '../views/pages/analise/index.vue')
  }
]

export default routes
