import { Investment } from '@/interfaces/pRest/investment'
import { ActionTree } from 'vuex'
import { StateInterface } from '../index'
import { InvestmentState } from './state'

const transformByKey = (list) => {
  return list.reduce((acc, cur) => {
    acc[cur.id] = cur
    return acc
  }, {})
}

const actions: ActionTree<InvestmentState, StateInterface> = {
  async load({ commit, rootGetters }) {
    const investmentModel = rootGetters['prest/model']('investment')
    const orderModel = rootGetters['prest/model']('order')
    const investorModel = rootGetters['prest/model']('investor')
    const invoiceModel = rootGetters['prest/model']('invoice')
    const addressModel = rootGetters['prest/model']('address')

    commit('setLoading', true)

    const investments = await investmentModel.run()

    const addresses = await addressModel.run().then(transformByKey)
    const orders = await orderModel.run().then(transformByKey)
    const investors = await investorModel.run().then(transformByKey)
    const invoices = await invoiceModel.run().then(transformByKey)

    investments.forEach((i: Investment) => {
      i.investidor = investors[i.investidor_id]
      i.investidor.endereco = addresses[i.investidor.endereco_id]
      i.ordem = orders[i.ordem_id]
      // i.ordem.invoice = invoices[i.ordem.invoice_id]
    })

    commit('setLoading', false)
    commit('setInvestments', investments)
  },

  async search({ commit, rootGetters }, { pagination, filters, order }) {
    const investmentModel = rootGetters['prest/model']('investment')
    const orderModel = rootGetters['prest/model']('order')
    const investorModel = rootGetters['prest/model']('investor')
    const invoiceModel = rootGetters['prest/model']('invoice')
    const addressModel = rootGetters['prest/model']('address')

    commit('setLoading', true)

    for (const f in filters) {
      if (!filters[f].value) break

      investmentModel
        .where({
          field: f,
          operator: filters[f].type,
          value: filters[f].value
        })
    }

    if (order) {
      investmentModel.order(order)
    }

    if (pagination) {
      investmentModel.pagination(pagination)
    }

    let investments = []

    const response = await investmentModel.run()

    if (pagination) {
      investments = response.items
    } else {
      investments = response
    }

    const orders = await orderModel.run().then(transformByKey)
    const investors = await investorModel.run().then(transformByKey)
    const invoices = await invoiceModel.run().then(transformByKey)
    const addresses = await addressModel.run().then(transformByKey)

    investments.forEach((i: Investment) => {
      i.investidor = investors[i.investidor_id]
      i.investidor.endereco = addresses[i.investidor.endereco_id]
      i.ordem = orders[i.ordem_id]
      // i.ordem.invoice = invoices[i.ordem.invoice_id]
    })

    commit('setLoading', false)

    if (pagination) {
      return Promise.resolve({ pagination: response.pagination, items: investments })
    } else {
      return Promise.resolve(investments)
    }
  }
}

export default actions
