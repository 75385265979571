import { DashboardUser } from '@/interfaces/dashboard'
import axios, { AxiosInstance, AxiosResponse } from 'axios'

export default class DashboardService {
  private DASHBOARD_ENDPOINT = process.env.VUE_APP_DASHBOARD_API;
  private INVESTMENT_ENDPOINT = 'https://investimento-dot-startmeup-218419.rj.r.appspot.com/investimento';

  client: AxiosInstance;

  constructor(client: AxiosInstance = axios) {
    this.client = client
  }

  findUserByEmail(email: string) {
    return this.client
      .get(`${this.DASHBOARD_ENDPOINT}/api/users`, { params: { email } })
      .then((response: AxiosResponse) => {
        const users: any[] = response.data

        if (users[0]) {
          return users[0]
        } else {
          throw new Error('Usuário não encontrado.')
        }
      })
  }

  findUserById(id: string) {
    return this.client
      .get(`${this.DASHBOARD_ENDPOINT}/api/users/${id}`)
      .then((response: AxiosResponse) => response.data)
  }

  allUsersCount(): Promise<number> {
    return this.client
      .get(`${this.DASHBOARD_ENDPOINT}/api/users`, { params: { _count: '*' } })
      .then((response: AxiosResponse) => {
        return response.data[0].count
      })
  }

  allUsers(options: any = {}): Promise<DashboardUser[]> {
    return this.client
      .get(`${this.DASHBOARD_ENDPOINT}/api/users`, { params: options })
      .then(response => response.data)
  }

  saveDashboardUser(form: DashboardUser): Promise<DashboardUser> {
    return this.client
      .post(`${this.DASHBOARD_ENDPOINT}/api/users`, { ...form })
      .then(respose => respose.data)
  }

  updateDashboardUser(form: DashboardUser): Promise<DashboardUser> {
    return this.client
      .put(`${this.DASHBOARD_ENDPOINT}/api/users/${form.id}`, { ...form })
      .then(response => response.data)
  }

  deleteDashboardUser(userId: string) {
    return this.client
      .delete(`${this.DASHBOARD_ENDPOINT}/api/users/${userId}`)
      .then(response => response.data)
  }

  resume() {
    return this.client
      .get(`${this.INVESTMENT_ENDPOINT}/dashboard/consolidado`)
      .then(response => response.data)
  }
}
