import { AuthState } from '@/interfaces/store'
import { Route } from 'vue-router'

export const isAuthenticated = (state, route: Route, next: Function) => {
  const authState: AuthState = state.auth

  if (authState.user && authState.auth && authState.dashboardUser) {
    next()
    return
  }

  next('/login')
}
