import { RouteConfig } from 'vue-router'
import { isAuthenticated } from '@/middlewares/isAuthenticated'
import { isRoleAllowed } from '@/middlewares/isRoleAllowed'
import { Role } from '@/interfaces/dashboard'

const routes: Array<RouteConfig> = [
  {
    path: '/configuracoes/novo-acesso',
    name: 'NewAccess',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ADMIN],
      layout: 'dashboard',
      title: 'Novo Acesso | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "config" */ '../views/pages/configuracoes/novo-acesso.vue')
  },

  {
    path: '/configuracoes/acessos',
    name: 'Access',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ADMIN],
      layout: 'dashboard',
      title: 'Lista de Acessos | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "config" */ '../views/pages/configuracoes/acessos.vue')
  },

  {
    path: '/configuracoes/acessos/:id/editar',
    name: 'EditAccess',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ADMIN],
      layout: 'dashboard',
      title: 'Editar Acesso | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "config" */ '../views/pages/configuracoes/editar-acesso.vue')
  }
]

export default routes
