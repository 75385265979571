import { RouteConfig } from 'vue-router'
import { isAuthenticated } from '@/middlewares/isAuthenticated'
import { isRoleAllowed } from '@/middlewares/isRoleAllowed'
import { Role } from '@/interfaces/dashboard'

const routes: Array<RouteConfig> = [
  {
    path: '/feed',
    name: 'Feed',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ANALISE, Role.ATENDIMENTO, Role.SUPORTE, Role.MARKETING, Role.COMERCIAL, Role.RI, Role.OFFER_ADMIN, Role.ADMIN],
      layout: 'dashboard',
      title: 'Feed | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "feed" */ '../views/pages/feed/index.vue')
  }
]

export default routes
