import { RouteConfig } from 'vue-router'
import { isAuthenticated } from '@/middlewares/isAuthenticated'
import { isRoleAllowed } from '@/middlewares/isRoleAllowed'
import { Role } from '@/interfaces/dashboard'

const routes: Array<RouteConfig> = [
  {
    path: '/ofertas',
    name: 'Offers',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ANALISE, Role.ATENDIMENTO, Role.SUPORTE, Role.MARKETING, Role.COMERCIAL, Role.RI, Role.OFFER_ADMIN, Role.ADMIN],
      layout: 'dashboard',
      title: 'Ofertas | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "offers" */ '../views/pages/ofertas/index.vue')
  },

  {
    path: '/ofertas/setup',
    name: 'NewOffer',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ADMIN, Role.OFFER_ADMIN],
      layout: 'dashboard',
      title: 'Nova Oferta | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "offers" */ '../views/pages/ofertas/setup.vue')
  },

  {
    path: '/ofertas/:url/editar',
    name: 'EditOffer',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ADMIN, Role.OFFER_ADMIN],
      layout: 'dashboard',
      title: 'Editar Oferta | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "offers" */ '../views/pages/ofertas/_url/editar.vue')
  },

  {
    path: '/ofertas/:url/consolidado',
    name: 'OfferFinished',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ANALISE, Role.ATENDIMENTO, Role.SUPORTE, Role.MARKETING, Role.COMERCIAL, Role.RI, Role.OFFER_ADMIN, Role.ADMIN],
      layout: 'dashboard',
      title: 'Relatório de Oferta Concluída | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "offers" */ '../views/pages/ofertas/_url/consolidado.vue')
  },

  {
    path: '/ofertas/ri',
    name: 'OfferRi',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.RI, Role.ADMIN],
      layout: 'dashboard',
      title: 'RI | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "offers" */ '../views/pages/ofertas/ri.vue')
  },

  {
    path: '/ofertas/setores-de-atuacao',
    name: 'OfferBusinessSegments',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.OFFER_ADMIN, Role.ADMIN],
      layout: 'dashboard',
      title: 'Setores de Atuação | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "offers" */ '../views/pages/ofertas/setores-de-atuacao.vue')
  },

  {
    path: '/ofertas/tags',
    name: 'OfferTags',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.OFFER_ADMIN, Role.ADMIN],
      layout: 'dashboard',
      title: 'Tags | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "offers" */ '../views/pages/ofertas/tags.vue')
  }
]

export default routes
