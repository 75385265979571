import axios, { AxiosInstance } from 'axios'

interface ModelConfig {
  domain?: string;
  db?: string;
  table?: string;
  https?: boolean;
  url?: string;
  authorization_token?: string;
  proxy?: string;
}

interface Pagination {
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
  prevPage: number;
  nextPage: number;
  isLast: boolean;
  isFirst: boolean;
  _q?: string;
}

interface PageOptions {
  page: number;
  pageSize: number;
}

interface JoinQuery {
  type: 'inner' | 'left' | 'right' | 'outer';
  tableJoin: string;
  tableJoinKey: string;
  operator: '$eq' | '$lt' | '$gt' | '$lte' | '$gte';
  table: string;
  tableKey: string;
}

interface WhereQuery {
  field: string;
  operator: '$eq' | '$gt' | '$gte' | '$lt' | '$lte' | '$ne' | '$in' | '$nin' | '$null' | '$notnull' | '$true' | '$nottrue' | '$false' | '$notfalse' | '$like' | '$ilike' ;
  value: string | number;
}

interface FilterQuery {
  a: string;
  b: string;
}

class BaseModel {
  client!: AxiosInstance

  constructor(config: ModelConfig) {
    let c: AxiosInstance

    if (config.authorization_token) {
      c = axios.create({
        headers: {
          authorization: `Bearer ${config.authorization_token}`
        }
      })

      this.client = c
    } else {
      this.client = axios
    }
  }
}

export {
  ModelConfig,
  Pagination,
  PageOptions,
  JoinQuery,
  WhereQuery,
  FilterQuery,
  BaseModel
}
