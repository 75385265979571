import { RouteConfig } from 'vue-router'
import { isAuthenticated } from '@/middlewares/isAuthenticated'
import { isRoleAllowed } from '@/middlewares/isRoleAllowed'
import { Role } from '@/interfaces/dashboard'

const routes: Array<RouteConfig> = [
  {
    path: '/investimentos',
    name: 'Investments',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ANALISE, Role.ATENDIMENTO, Role.SUPORTE, Role.MARKETING, Role.COMERCIAL, Role.RI, Role.OFFER_ADMIN, Role.ADMIN],
      layout: 'dashboard',
      title: 'Investimentos | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "investments" */ '../views/pages/investimentos/index.vue')
  },

  {
    path: '/investimentos/oferta',
    name: 'SelectOfferPage',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ANALISE, Role.ATENDIMENTO, Role.SUPORTE, Role.MARKETING, Role.COMERCIAL, Role.RI, Role.OFFER_ADMIN, Role.ADMIN],
      layout: 'dashboard',
      title: 'Investimentos | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "investments" */ '../views/pages/investimentos/oferta/index.vue')
  },

  {
    path: '/investimentos/lista-de-espera',
    name: 'selectOfferInvestmentQueue',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ANALISE, Role.ATENDIMENTO, Role.SUPORTE, Role.MARKETING, Role.COMERCIAL, Role.RI, Role.OFFER_ADMIN, Role.ADMIN],
      layout: 'dashboard',
      title: 'Ofertas em Lista de Espera | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "investments" */ '../views/pages/investimentos/lista-de-espera/index.vue')
  },

  {
    path: '/investimentos/lista-de-espera/:url',
    name: 'investmentQueueByOffer',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ANALISE, Role.ATENDIMENTO, Role.SUPORTE, Role.MARKETING, Role.COMERCIAL, Role.RI, Role.OFFER_ADMIN, Role.ADMIN],
      layout: 'dashboard',
      title: 'Lista de Espera | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "investments" */ '../views/pages/investimentos/lista-de-espera/_url.vue')
  },

  {
    path: '/investimentos/oferta/:url',
    name: 'InvestmentsByOffer',
    meta: {
      middlewares: [isAuthenticated, isRoleAllowed],
      allowedRoles: [Role.ANALISE, Role.ATENDIMENTO, Role.SUPORTE, Role.MARKETING, Role.COMERCIAL, Role.RI, Role.OFFER_ADMIN, Role.ADMIN],
      layout: 'dashboard',
      title: 'Investimentos | SMU Investimentos'
    },
    component: () => import(/* webpackChunkName: "investments" */ '../views/pages/investimentos/oferta/_url/index.vue')
  }
]

export default routes
